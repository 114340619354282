// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$curator-frontend-primary: mat.define-palette(mat.$indigo-palette);
$curator-frontend-accent: mat.define-palette(
  mat.$orange-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$curator-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$curator-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $curator-frontend-primary,
      accent: $curator-frontend-accent,
      warn: $curator-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($curator-frontend-theme);

/* You can add global styles to this file, and also import other style files */

$primary: map-get(
  $map: $curator-frontend-primary,
  $key: 500,
);
$primary-darker: map-get(
  $map: $curator-frontend-primary,
  $key: 900,
);
$accent: map-get(
  $map: $curator-frontend-accent,
  $key: A200,
);
$warn: map-get(
  $map: mat.$red-palette,
  $key: 500,
);

:root {
  --primary: #{$primary};
  --primary-darker: #{$primary-darker};
  --accent: #{$accent};
  --accent-rgb: 255, 171, 64;
  --highlight: #f9c300;
  --highlight-rgb: 249, 195, 0;
  --warn: #{$warn};
  --warn-rgb: 244, 67, 53;
  --grey: #e0e0e0;
  --background: #f5f6fa;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f5f6fa;
}

// generic (mostly) base classes
.mat-card,
.mat-expansion-panel {
  border-radius: 9px !important;
}

.mat-chip::after {
  background: none !important;
}

.app-container {
  height: 100%;
}

.container {
  margin: auto;
  width: 90%;
  padding-bottom: 2rem;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-adjusted {
  height: calc(100% - (68px + 1rem));
}

.space-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.btn-icon {
  padding-left: 0.5rem;
}

.link {
  cursor: pointer;
}

.text-gray {
  color: rgba(0, 0, 0, 0.6);
}

.dialog-panel-small {
  width: 320px;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

// Flex base classes
.flex {
  display: flex;

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-g1 {
    display: flex;
    gap: 1rem;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.no-wrap {
  flex-wrap: nowrap;
}

.hover-button {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginator {
  margin-bottom: 1rem;
  position: fixed;
  bottom: 0%;
  left: 5%;
  border-radius: 1rem;
  z-index: 10;
}
